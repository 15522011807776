/* start RCamera */

._3K3d5 {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 10;
  box-sizing: border-box !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

._3K3d5 * {
  box-sizing: border-box !important;
}

._3K3d5 > p {
  margin: auto;
  color: white;
}

._2Oywl,
._2HyPD {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

._2HyPD {
  position: absolute;
  z-index: 10;
  background-color: black;
}

._2QrFx,
._2Ocup {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

._2QrFx > video,
._2Ocup > img {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

._2QrFx > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}

._2eNuK,
._1koaR {
  display: flex;
  width: 100%;
  margin-top: auto;
}

._3K3d5 button {
  width: 100%;
  text-align: center;
  background-color: gray;
  padding: 16px;
  border: none;
  transition: 0.3s;
  cursor: pointer;
}

._3K3d5 button:hover {
  opacity: 0.7;
}

._3K3d5 button:active {
  opacity: 0.5;
}

._3K3d5 button:focus {
  outline: none;
}

._Pc1qz {
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-name: _n54lF;
          animation-name: _n54lF;
}

@-webkit-keyframes _n54lF {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes _n54lF {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

._1pOR4 {
  background-color: red !important;
}

._28C9y {
  position: absolute;
  display: flex;
  bottom: 1rem;
  left: 0;
  right: 0;
}

._1XD7B {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 1rem;
}

._2k75f {
  display: inline-flex;
  margin: auto;
  padding: 1rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
}

._2k75f:hover {
  opacity: 0.7;
}

._2k75f:active {
  opacity: 0.5;
}

/* end RCamera */
